import {useContext} from 'react';

import Modal from '../UI/Modal';
import CartItem from './CartItem';
import classes from './Cart.module.css';
import CartContext from '../../store/cart-context';

const Cart = (props) => {
    const cartCtx = useContext(CartContext);

    const totalAmount = `BAM ${cartCtx.totalAmount.toFixed(2)}`;
    const hasItems = cartCtx.items.length > 0;

    const cartItemRemoveHandler = (id) => {
        cartCtx.removeItem(id);
    };

    const cartItemAddHandler = (item) => {
        cartCtx.addItem({...item, amount: 1});
    };
    const handleOrder = () => {
        props.onClose()
        cartCtx.clearCart();
    }
    const cartItems = (
        <ul className={classes['cart-items']}>
            {!!cartCtx.items.length ? cartCtx.items.map((item) => (
                <CartItem
                    key={item.id}
                    name={item.name}
                    image={item.image}
                    amount={item.amount}
                    price={item.price}
                    onRemove={cartItemRemoveHandler.bind(null, item.id)}
                    onAdd={cartItemAddHandler.bind(null, item)}
                />
            )) : <li style={{textAlign: 'center'}}>Vaša korpa je prazna!</li>}
        </ul>
    );

    return (
        <Modal onClose={props.onClose}>
            {cartItems}
            <div className={classes.total}>
                <span>Ukupan iznos</span>
                <span>{totalAmount}</span>
            </div>
            <div className={classes.actions}>
                <button className={classes['button--alt']} onClick={props.onClose}>
                    Zatvori
                </button>
                {hasItems && <button className={classes.button} onClick={handleOrder}>
                    Naruči
                </button>}
            </div>
        </Modal>
    );
};

export default Cart;
