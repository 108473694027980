import { Fragment } from 'react';

import HeaderCartButton from './HeaderCartButton';
import classes from './Header.module.css';

const Header = (props) => {
  return (
    <Fragment>
      <header className={classes.header}>
      <div>
          <img src="https://sinergija.edu.ba/wp-content/uploads/2021/11/univerzitet-sinergija-logo-white.png"  alt="University logo"/>
      </div>
        <HeaderCartButton onClick={props.onShowCart} />
      </header>
      <div className={classes['main-image']}>
        <img src={'https://images.unsplash.com/photo-1550547660-d9450f859349?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2097&q=80'} alt='A table full of delicious food!' />
      </div>
    </Fragment>
  );
};

export default Header;
