import Card from '../UI/Card';
import MealItem from './MealItem/MealItem';
import classes from './AvailableMeals.module.css';
import MealsFilter from "./MealsFilter";
import {useState} from "react";

const DUMMY_MEALS = [
    {
        "id": "m1",
        "name": "Suši",
        "description": "Najfinija riba i povrće",
        "image": "https://images.unsplash.com/photo-1579584425555-c3ce17fd4351?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2270&q=80",
        "price": 13.99,
        "categories": ["japanska kuhinja", "sushi"]
    },
    {
        "id": "m2",
        "name": "Šnicla",
        "description": "Nemačka specijalnost!",
        "image": "https://images.unsplash.com/photo-1546964124-0cce460f38ef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80",
        "price": 16.5,
        "categories": ["nemačka kuhinja", "meso"]
    },
    {
        "id": "m3",
        "name": "Roštiljska Pljeskavica",
        "description": "Američka, sočna, mesnata",
        "image": "https://images.unsplash.com/photo-1529042222786-e26b38309122?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80",
        "price": 12.99,
        "categories": ["američka kuhinja", "meso"]
    },
    {
        "id": "m4",
        "name": "Zelena Salata",
        "description": "Zdravo... i zeleno...",
        "image": "https://images.unsplash.com/photo-1514537099923-4c0fc7c73161?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2592&q=80",
        "price": 18.99,
        "categories": ["salate", "zdrava ishrana"]
    },
    {
        "id": "m5",
        "name": "Pica Margarita",
        "description": "Klasična italijanska pica sa paradajz sosom i mocarelom",
        "price": 14.99,
        "image": "https://source.unsplash.com/600x400/?pizza",
        "categories": ["italijanska kuhinja", "pica"]
    },
    {
        "id": "m6",
        "name": "Pileći Kari",
        "description": "Sočno piletina sa kari sosom i svežim povrćem",
        "price": 17.5,
        "image": "https://source.unsplash.com/600x400/?curry",
        "categories": ["azijska kuhinja", "piletina"]
    },
    {
        "id": "m7",
        "name": "Palačinke sa Voćem",
        "description": "Slasne palačinke sa svežim voćem i javorovim sirupom",
        "price": 9.99,
        "image": "https://source.unsplash.com/600x400/?pancakes",
        "categories": ["deserti", "palačinke"]
    },
    {
        "id": "m8",
        "name": "Slatki Krompir Pomfrit",
        "description": "Hrskavi slatki krompir pomfrit sa maslinovim uljem",
        "price": 8.99,
        "image": "https://source.unsplash.com/600x400/?potato-fries",
        "categories": ["prilozi", "slatki krompir"]
    },
    {
        "id": "m9",
        "name": "Losos sa Povrćem",
        "description": "Sveže pečeni losos sa mešanim povrćem",
        "price": 19.99,
        "image": "https://images.unsplash.com/photo-1519708227418-c8fd9a32b7a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80",
        "categories": ["riblji specijaliteti", "losos"]
    },
    {
        "id": "m10",
        "name": "Meksikanska Tako Salata",
        "description": "Kombinacija sočnih taka, povrća i avokada",
        "price": 13.5,
        "image": "https://source.unsplash.com/600x400/?taco",
        "categories": ["meksikanska kuhinja", "tako"]
    },
    {
        "id": "m11",
        "name": "Piletina Alfredo",
        "description": "Sočna piletina u bogatom Alfredo sosu",
        "price": 16.99,
        "image": "https://images.unsplash.com/photo-1516684465974-78661ba8165d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80",
        "categories": ["italijanska kuhinja", "piletina"]
    },
    {
        "id": "m12",
        "name": "Veganska Quinoa Salata",
        "description": "Sveža quinoa sa povrćem i prelivom od avokada",
        "price": 15.99,
        "image": "https://source.unsplash.com/600x400/?vegan-salad",
        "categories": ["veganska kuhinja", "quinoa"]
    },
    {
        "id": "m13",
        "name": "Rižoto sa Pečurkama",
        "description": "Bogat rižoto sa svežim pečurkama i parmezanom",
        "price": 14.99,
        "image": "https://plus.unsplash.com/premium_photo-1695030933912-5470602c4f15?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cmlzb3R0byUyMHdpdGglMjBtdXNocm9vbXN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
        "categories": ["italijanska kuhinja", "rižoto"]
    },
    {
        "id": "m14",
        "name": "Čokoladni Fondan",
        "description": "Topli čokoladni fondan sa kuglom sladoleda",
        "price": 9.99,
        "image": "https://source.unsplash.com/600x400/?chocolate-cake",
        "categories": ["deserti", "čokolada"]
    },
    {
        "id": "m15",
        "name": "Grčka Salata",
        "description": "Osvežavajuća grčka salata sa maslinama i feta sirom",
        "price": 12.5,
        "image": "https://source.unsplash.com/600x400/?greek-salad",
        "categories": ["grčka kuhinja", "salate"]
    }
];
const DUMMY_CATEGORIES = [
    "japanska kuhinja",
    "sushi",
    "nemačka kuhinja",
    "meso",
    "američka kuhinja",
    "salate",
    "zdrava ishrana",
    "italijanska kuhinja",
    "pica",
    "azijska kuhinja",
    "piletina",
    "deserti",
    "palačinke",
    "prilozi",
    "slatki krompir",
    "riblji specijaliteti",
    "losos",
    "meksikanska kuhinja",
    "tako",
    "veganska kuhinja",
    "quinoa",
    "rižoto",
    "čokolada",
    "grčka kuhinja"
];
const AvailableMeals = () => {
    const [mealList, setMealList] = useState(DUMMY_MEALS)

    const changeCategoryHandler = (event) => {
        const {value} = event.target;
        const isClearFilter = value === 'all';
        const mealToSave = isClearFilter ? DUMMY_MEALS : DUMMY_MEALS.filter(meal => meal.categories.some(category => category === value))
        setMealList(mealToSave);
    }
    const cleanAndLowercase = (text) => {
        return text.trim().toLowerCase();
    }
    const changeHandler = (event) => {
        const {value} = event.target;
        const valueToFind = cleanAndLowercase(value);
        const listToFind = DUMMY_MEALS.filter(meal => cleanAndLowercase(meal.name).includes(valueToFind))
        setMealList(listToFind)
    }

    return (
        <section className={classes.meals}>
            <Card>
                <MealsFilter categories={DUMMY_CATEGORIES} changeHandler={changeHandler}
                             changeCategoryHandler={changeCategoryHandler}/>
            </Card>
            <Card>
                <ul>
                    {!!mealList?.length ? mealList.map((meal) => (
                        <MealItem
                            key={meal.id}
                            id={meal.id}
                            name={meal.name}
                            image={meal.image}
                            categories={meal.categories}
                            description={meal.description}
                            price={meal.price}
                        />
                    )) : <ul style={{textAlign: 'center'}}>Lista je prazna, ne postoji proizvod za izabranim
                        filterima!</ul>}
                </ul>
            </Card>
        </section>
    );
};

export default AvailableMeals;
