import classes from './MealsSummary.module.css';

const MealsSummary = () => {
  return (
    <section className={classes.summary}>
      <h2>Ukusna Hrana, Dostavljena Do Vas</h2>
      <p>
          Izaberite svoje omiljeno jelo iz našeg širokog izbora dostupnih jela i uživajte u ukusnom ručku ili večeri kod kuće.
      </p>
      <p>
          Sva naša jela se pripremaju sa visokokvalitetnim sastojcima, upravo na vreme i naravno od strane iskusnih kuvara!
      </p>
    </section>
  );
};

export default MealsSummary;
