import React from 'react';
import classes from './Fotter.module.css';

const Footer = () => {
    return (
        <footer className={classes.footer}>
            <h5 className={classes.footerTitle}>
                RAZVOJ KLIJENTSKOG DIJELA VEB APLIKACIJE KORIŠĆENJEM BIBLIOTEKE REACT · DIPLOMSKI RAD · ĐORĐIJE LAZAREVIĆ
            </h5>
        </footer>
    );
};

export default Footer;
