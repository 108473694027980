import React from 'react';
import classes from './MeelsFilter.module.css';

const MealsFilter = (props) => {
    return (
        <div className={classes.filterWrapper}>
            <div className={classes.inputWrapper}>
                <label htmlFor="categories">Kategorije: </label>
                <select name="category" onChange={props.changeCategoryHandler} className={classes.inputField}>
                    <option value="all">Sve</option>
                    {props.categories?.map(category => {
                        return (
                            <option value={category} key={category}>{category}</option>
                        )
                    })}
                </select>
            </div>

            <div className={classes.inputWrapper}>
                <label htmlFor="title">Pretraži po imenu: </label>
                <input type="text" onChange={props.changeHandler} className={classes.inputField}/>
            </div>
        </div>
    );
};

export default MealsFilter;
