import classes from './CartItem.module.css';

const CartItem = (props) => {
    const price = `BAM ${props.price.toFixed(2)}`;

    return (
        <li className={classes['cart-item']}>
            <div className={classes.productWrapper}>
                <img src={props.image} alt={`${props.name} product`} className={classes.productImage}/>
                <div className={classes.productInfo}>
                    <h2>{props.name}</h2>
                    <span className={classes.price}>{price}</span>
                    <span className={classes.amount}>x {props.amount}</span>
                </div>
            </div>
            <div className={classes.actions}>
                <button onClick={props.onRemove}>−</button>
                <button onClick={props.onAdd}>+</button>
            </div>
        </li>
    );
};

export default CartItem;
