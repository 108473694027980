import {useContext} from 'react';

import MealItemForm from './MealItemForm';
import classes from './MealItem.module.css';
import CartContext from '../../../store/cart-context';

const MealItem = (props) => {
    const cartCtx = useContext(CartContext);

    const price = `BAM ${props?.price?.toFixed(2)}`;

    const addToCartHandler = amount => {
        cartCtx.addItem({
            id: props.id,
            name: props.name,
            image: props.image,
            amount: amount,
            price: props.price
        });
    };

    return (
        <li className={classes.meal}>
            <div>
                <div className={classes.mealWrapper}>
                    <img src={props.image} alt={`${props.name} product`} className={classes.productImage}/>
                    <div className={classes.mealTitle}>
                        <h3>{props.name}</h3>
                        <div className={classes.description}>
                            {props.description}
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '0.5rem'}}>
                    {props.categories?.map(category => {
                        return (
                            <span key={category} className={classes.productCategory}>{category} </span>
                        )
                    })}
                </div>
                <div className={classes.price}>{price}</div>
            </div>
            <div>
                <MealItemForm id={props.id} onAddToCart={addToCartHandler}/>
            </div>
        </li>
    );
};

export default MealItem;
